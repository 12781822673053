export default {
	computed: {
		showDrawer: {
			get() {
				return this.$store.getters.showDrawer;
			},
			set(value) {
				this.$store.dispatch('updateDrawer', value);
			},
		},
		isLarge() {
			return this.$vuetify.breakpoint.width > 1230;
		},
		isMedium() {
			const width = this.$vuetify.breakpoint.width;
			return width <= 1230 && width >= 1000;
		},
		isMobile() {
			return this.$vuetify.breakpoint.width < 1000;
		},
	},
};
