<template>
	<div :class="isMobile ? 'customize-page-mobile' : 'customize-page'">
		<CustomizeSidebar />
		<router-view></router-view>
	</div>
</template>

<script>
import CustomizeSidebar from '@/views/customize/components/CustomizeSidebar';
import CustomizeMixin from '@/views/customize/mixin/CustomizeMixin.js';

export default {
	mixins: [CustomizeMixin],
	name: 'CustomizePage',
	components: { CustomizeSidebar },
	async mounted() {
		await this.$store.dispatch('getCommands');
	},
};
</script>

<style scoped>
.customize-page {
	width: 100%;
	height: 100%;
	display: flex;
}

.customize-page-mobile {
	width: 100%;
	height: min-content;
	display: flex;
}
</style>
